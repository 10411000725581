import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Analytics from 'src/utils/Analytics'
import ConditionalCTA from 'src/components/ConditionalCTA'
import LoadingAnimation from 'src/components/LoadingAnimation'
import SEO from 'src/components/seo'
import { BuyNowLink } from 'src/utils/styles/global-styles'
import { BuyNowAnchor } from 'src/components/Hero/styles'
import {
  Section,
  Container,
  Text,
  Header,
  Content,
  BlogImageContainer,
  ButtonContainer,
  BlogPostsContainer,
  BlogImage,
} from '../../utils/styles/singleColumnBlog'

class SingleColumnBlogPage extends React.Component {
  componentDidMount() {
    const { pageContext } = this.props
    if (!pageContext?.name) return
    Analytics.track(`Viewed ${pageContext.name} Blog Post Landing Page`)
  }

  render() {
    const { pageContext } = this.props
    if (!pageContext?.fields) return <LoadingAnimation />
    const {
      slug,
      fields: {
        seo,
        hero,
        sections,
        footer: { cta },
        indexing,
      },
    } = pageContext
    return (
      <Section>
        <SEO title={seo.title} description={seo.meta_description} path={`/${slug}`} />
        {!indexing && (
          <Helmet>
            <meta name="robots" content="noindex" />
          </Helmet>
        )}
        <Container>
          <Text>
            <Header>{hero.headline}</Header>
            <p>{hero.sub_headline}</p>
          </Text>
          <BlogPostsContainer>
            {sections.map((section, i) => (
              <Content key={i}>
                <>
                  {sections[i].image ? (
                    <>
                      <BlogImageContainer>
                        <BlogImage src={sections[i].image} alt={sections[i].image_title} />
                      </BlogImageContainer>
                      <Text left dangerouslySetInnerHTML={{ __html: section.text }} />
                    </>
                  ) : (
                    <Text margin="auto" left dangerouslySetInnerHTML={{ __html: section.text }} />
                  )}
                  {sections[i].buy_now_button && (
                    <ButtonContainer>
                      <ConditionalCTA
                        url={hero.cta_button_link}
                        text={hero.cta_button_text}
                        anchorComponent={BuyNowAnchor}
                        linkComponent={BuyNowLink}
                      />
                    </ButtonContainer>
                  )}
                </>
              </Content>
            ))}
            {cta && (
              <ButtonContainer>
                <ConditionalCTA
                  url={hero.cta_button_link}
                  text={cta}
                  anchorComponent={BuyNowAnchor}
                  linkComponent={BuyNowLink}
                />
              </ButtonContainer>
            )}
          </BlogPostsContainer>

          <br />
        </Container>
      </Section>
    )
  }
}

SingleColumnBlogPage.propTypes = {
  pageContext: PropTypes.shape({
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string.isRequired,
        meta_description: PropTypes.string.isRequired,
      }),
      hero: PropTypes.shape({
        headline: PropTypes.string.isRequired,
        cta_button_link: PropTypes.string.isRequired,
        cta_button_text: PropTypes.string.isRequired,
        sub_headline: PropTypes.string,
      }),
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          image: PropTypes.string,
          image_title: PropTypes.string,
          text: PropTypes.string.isRequired,
          buy_now_button: PropTypes.string,
        })
      ),
      footer: PropTypes.shape({
        cta: PropTypes.string,
      }),
      indexing: PropTypes.bool.isRequired,
    }),
  }),
}

export default SingleColumnBlogPage
