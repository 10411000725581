import styled from 'styled-components'
import { breakpoints, BigHeader } from 'src/utils/styles/global-styles'
import { lighten } from 'polished'

export const Section = styled.div`
  background-color: ${(props) => props.theme.cream};
`
export const Container = styled.section`
  max-width: 800px;
  margin: auto;
`
export const Text = styled.section`
  max-width: 90%;
  margin: auto;
  @media (max-width: ${breakpoints.m}px) {
    max-width: 80%;
  }
`
export const Header = styled(BigHeader)`
  margin: 2rem 0px;
  @media (max-width: ${breakpoints.l}px) {
    font-size: 38px;
  }
  @media (max-width: ${breakpoints.m}px) {
    font-size: 1.5em;
  }
`

export const BlogPostsContainer = styled.section`
  h1,
  h2,
  h3 {
    font-family: 'P22MackinacProBold';
  }
  em {
    font-style: normal;
    font-family: 'P22MackinacProBook' !important;
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 0.13px;
    color: ${(props) => props.theme.grayDark};
    margin-bottom: 10px;
    @media (max-width: ${breakpoints.s}px) {
      font-size: 18px;
      line-height: 28px;
    }
  }
`
export const Content = styled.div`
  font-family: 'FilsonPro';
  align-items: center;
  a {
    color: white;
    text-decoration: none;
    &:hover,
    &:focus {
      outline: none;
      cursor: pointer;
      color: white;
    }
    span {
      color: ${(props) => props.theme.blueDark};
      text-decoration: none;
      &:hover,
      &:focus {
        outline: none;
        cursor: pointer;
        color: ${(props) => lighten(0.1, props.theme.blueDark)};
      }
    }
  }
  @media (max-width: ${breakpoints.l}px) {
    margin: 0px 0px;
    flex-wrap: wrap;
  }
  @media (max-width: ${breakpoints.m}px) {
    margin: 0px 0px;
  }
  p,
  span {
    font-family: 'FilsonProBook';
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1px;
    margin: 10px 0px;
    @media (max-width: 1100px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: ${breakpoints.m}px) {
      font-size: 18px;
      line-height: 24px;
    }
    @media (max-width: ${breakpoints.s}px) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

export const BlogImageContainer = styled.div`
  max-width: 90%;
  margin: 40px auto;
  @media (max-width: ${breakpoints.m}px) {
    max-width: 80%;
  }
  @media (max-width: ${breakpoints.s}px) {
    max-width: 100%;
    margin: 20px auto;
  }
`
export const ButtonContainer = styled.div`
  text-align: center;
  margin: 50px auto;
`

export const BlogImage = styled.img`
  width: 100%;
  border-radius: 36px;
  @media (max-width: ${breakpoints.s}px) {
    border-radius: 0px;
  }
`
